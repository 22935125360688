import {
    CenteredContentWrapper,
    device,
    EmphasizedText,
    Quote,
    Subheading,
    TextToutFullwidth,
    ThumbnailHeader,
} from "@xolvio/xolvio-ui";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import {MoveHack, ToutContent, ToutHeader, ToutTextBody, ToutWrapper} from "../../components/Services";
import {LayoutDefault} from "../../components/LayoutDefault";
import {HeroBackgroundImage} from "../../components/HowWeWork";
import {ContentWrapper} from "quality-faster/src/components/LandingPage/ContentWrapper";
import {SectionHeader, TextBody} from "@xolvio/xolvio-ui/src"
import styled from 'styled-components';
import {AlternateBackground, ToutRow} from "../../components/Homepage";
import {contactUsFooterProps} from '../../pages-content/homepage'

const Page = ({data}) => {
    return (
        <>
            <ToutWrapper>
                <ToutContent>
                    <ToutHeader>
                        <EmphasizedText text={"Audi of America"} highlight={"aaaa"}/>
                    </ToutHeader>
                    <ToutTextBody style={{textAlign: 'center'}}>{"e-tron reservations system"}</ToutTextBody>
                </ToutContent>
            </ToutWrapper>

            <MoveHack top={"150px"} topMobile={'50px'}/>

            <CenteredContentWrapper>
                <Quote body={"Xolvio offers a level of experience and cross-functional value that we have never encountered anywhere else, nor have we been able to find from any single organization."} signature={"Jeff Titus, Audi"}/>
            </CenteredContentWrapper>

            <MoveHack top={"150px"}/>

            <HeroBackgroundImage
                fluid={data.howItWorksBg.childImageSharp.fluid}
                durationFadeIn={200}
                style={{
                    position: "absolute", // this neeeds to be set here
                    width: "200%",
                    height: "320px",
                }}
            />

            <CenteredContentWrapper style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "-50px"}}>
                <SectionHeaderStyled>
                    Bespoke headless e-reservation platform
                </SectionHeaderStyled>
                <StyledTextBody>
                    As one of the leading premium car manufacturers, Audi of America was about to enter the electric
                    vehicle market and decided to create an e-reservation system to promote their soon-to-be-announced
                    e-tron car. High complexity and performance requirements together with a tight deadline meant the
                    headless e-tron reservations system had to be carefully architectured and work flawlessly from day
                    one. To make all of this possible, we designed a distributed micro services architecture that
                    separated the different business domains within the solution, allowing multiple teams to work in
                    parallel. By using a combination of Domain-Driven Design and Behavior-Driven Development, we managed
                    to collaboratively write specifications able to address edge cases inherent to the system’s complexity,
                    while automated tests verified the proper handling of every use case, including edge cases. Next,
                    we added the UI, which seamlessly connected to the headless backend of the system. Finally, through
                    rigorous performance testing and tuning we made the e-tron reservations system capable of processing
                    thousands of reservations per minute. In the end, the solution was developed in record time and on
                    time, performed perfectly and at scale from the get-go, plus it was more than ready to be further
                    evolved and continues to be in use today.
                </StyledTextBody>
            </CenteredContentWrapper>

            <MoveHack top={"50px"}/>

            <CenteredContentWrapper id="howWeWork">
                <ContentWrapper>
                    <Subheading style={{ textAlign: "center" }}>
                        {"THE CHALLENGE"}
                    </Subheading>
                    <SectionHeader style={{maxWidth: "1200px", textAlign: "center"}}>
                        {"Creating a complex digital product in record time"}
                    </SectionHeader>
                    <p style={{textAlign: 'left'}}>
                        {"The e-tron car launch meant a new era for Audi, where the high quality brand joined the electric vehicle revolution. Correspondingly, the marketing team devised a campaign to promote the launch with a highly complex e-reservation system playing a key role, so as to assert brand position within the traditional automotive space. This was the first time the company undertook such a complex e-commerce project and Audi’s marketing team was facing serious challenges:"}
                    </p>
                    <MoveHack top={"50px"}/>

                    <ToutRow style={{justifyContent: "center"}}>
                        <div>
                            <ThumbnailHeader style={{ margin: "auto" }}>
                                {"Creating demand for the e-tron"}
                            </ThumbnailHeader>
                            <TextBody style={{ margin: "auto", textAlign: "left" }}>
                                {"Apart from the basic reservation taking functionality, the platform was meant to create marketing buzz around the e-tron car. Audi’s team devised features that created demand for the new car, which in turn immensely increased the solution’s complexity."}
                            </TextBody>
                        </div>
                        <div>
                            <ThumbnailHeader style={{ margin: "auto" }}>
                                {"High performance requirements"}
                            </ThumbnailHeader>
                            <TextBody style={{ margin: "auto", textAlign: "left" }}>
                                {"The reservation system had to be capable of processing thousands of reservations per minute to meet the expected demand for the e-tron car sales. This furthered the need for the solution to be of as high quality as possible."}
                            </TextBody>
                        </div>

                        <div>
                            <ThumbnailHeader style={{ margin: "auto" }}>
                                {"Breakneck deadline"}
                            </ThumbnailHeader>
                            <TextBody style={{ margin: "auto", textAlign: "left" }}>
                                {"For various reasons, not least because of complications that can occur within a large enterprise software project, Audi was left with only around 4 months to prepare the reservation system for the product launch."}
                            </TextBody>
                        </div>
                    </ToutRow>
                </ContentWrapper>
            </CenteredContentWrapper>

            <MoveHack top={"50px"}/>

            <CenteredContentWrapper style={{display: "flex", flexDirection: "column"}}>
                <Subheading>
                    {"THE SOLUTION"}
                </Subheading>
                <SectionHeader style={{maxWidth: "100%"}}>
                    {"State-of-the art digital architecture for flawless and timely delivery"}
                </SectionHeader>
                <Solution>
                    <SolutionParagraph>
                        The e-tron platform had to be delivered in record time while retaining high quality to match the
                        brand. This required the right digital architecture, so that we could move at speed with the delivery.
                        For that reason, we started our work by migrating from Audi’s existing cloud infrastructure to an
                        advanced distributed microservices architecture, where each team was responsible for one aspect
                        of the system.
                    </SolutionParagraph>
                    <SolutionParagraph>
                        In order to clearly divide up the work between the teams, we applied Domain-Driven Design to
                        arrive at separate business domains with the correct boundaries and bounded contexts. When the
                        overall problem was structured into the separate domains, we proceeded with using Behavior-Driven
                        Development within each domain to gather specifications and set up automated tests. In effect,
                        all of the work needed to be done to create the e-tron reservation platform could be realized
                        in parallel, so that Audi would meet the looming deadline.
                    </SolutionParagraph>
                </Solution>
                <Solution>
                    <SolutionParagraph >
                        Our use of Behavior-Driven Development and writing specifications collaboratively across Audi’s
                        teams also allowed us to formulate edge cases for how the reservation platform was going to work.
                        That way, we were able to tackle the system’s immense complexity and ensure it would work
                        flawlessly from day one. Meanwhile, in order to meet the high performance requirements, we put
                        a lot of effort into performance testing and tuning, so that the platform could handle
                        thousands of reservations per minute.
                    </SolutionParagraph>
                    <SolutionParagraph >
                        The headless solution required two frontends: one for the customers and one for the back office
                        with real time data dashboards. In order to stitch the distributed microservices together to  enable
                        a single view of data to the frontends, we created one of the first ever distributed GraphQL implementations,
                        working closely with the Apollo team and consequently helping shape the product now known as
                        Apollo Federation. Finally, we integrated the payment gateway into the solution by building a
                        facade around it that was plugged into the GraphQL layer.
                    </SolutionParagraph>
                </Solution>
            </CenteredContentWrapper>

            <MoveHack top={"50px"}/>

            <CenteredContentWrapper style={{display: "flex", flexDirection: "column"}}>
                <Subheading>
                    {"THE ROLE OF GRAPHQL"}
                </Subheading>
                <SectionHeader style={{maxWidth: "100%"}}>
                    {"How we came up with a pioneering distributed GraphQL implementation"}
                </SectionHeader>
                <Solution>
                    <SolutionParagraph>
                        Previously, we explained how the use of Domain-Driven Design allowed us to make all of the teams
                        work independently of each other, consequently speeding up the development process. However,
                        this great win gave birth to a new challenge: how to bring all of the distributed data back together for the frontends.
                    </SolutionParagraph>
                    <SolutionParagraph>
                        We turned to GraphQL as the solution to that problem. At the time, Apollo Federation was still
                        under development, so we investigated the promising practice of schema stitching. This only solved
                        half the issue by giving us a single API endpoint to get all of the graphs in one place. What it
                        didn't solve was the ability to aggregate the data effectively.
                    </SolutionParagraph>
                </Solution>
                <Solution>
                    <SolutionParagraph >
                        In order to join all of the data, we opted for custom dynamic resolvers. Our work here was greatly
                        facilitated by Apollo Studio, which gave us unprecedented insights into how the system was operating.
                        The Studio also opened up a new avenue for QA on a data level. Looking to further leverage Apollo’s
                        tooling in the project, we got in touch with their team.
                    </SolutionParagraph>
                    <SolutionParagraph >
                        It turned out Apollo was already working on Federation to solve the kind of issues we were
                        facing. Matt DeBergalis, Apollo CTO, came to work with Audi and Xolvio to understand the use
                        case for the purpose of Federation development. In the end, our custom schema stitching/resolver
                        aggregation solution for the e-reservation platform’s initial release was a success, while our
                        use case greatly helped inform and shape Apollo Federation.
                    </SolutionParagraph>
                </Solution>
            </CenteredContentWrapper>

            <MoveHack top={"100px"}/>

            <AlternateBackground>
                <CenteredContentWrapper>
                    <ContentWrapper>
                        <Subheading style={{ textAlign: "center" }}>
                            {"THE OUTCOME"}
                        </Subheading>
                        <SectionHeader style={{maxWidth: "1200px", textAlign: "center"}}>
                            {"A product launch that elevated the brand"}
                        </SectionHeader>
                        <AlignToLeftOnMobileSolutionParagraph>
                            {"Working together with Audi’s IT department and multiple partner agencies, we successfully responded to all of our client’s requirements which resulted in stellar outcomes:"}
                        </AlignToLeftOnMobileSolutionParagraph>
                        <MoveHack top={"50px"} topMobile={'100px'}/>

                        <ToutRow style={{justifyContent: "center"}}>
                            <div>
                                <ThumbnailHeader style={{ margin: "auto" }}>
                                    {"Top-notch quality solution"}
                                </ThumbnailHeader>
                                <TextBody style={{ margin: "auto", textAlign: "left" }}>
                                    {"We made sure no stone was left unturned while gathering specifications, which enabled us to tackle the e-tron reservation platform’s complexity when it came to edge cases. By means of rigorous performance testing and tuning we maxed out the solution’s capability to handle large volumes of commerce traffic."}
                                </TextBody>
                            </div>
                            <div>
                                <ThumbnailHeader style={{ margin: "auto" }}>
                                    {"Record delivery time"}
                                </ThumbnailHeader>
                                <TextBody style={{ margin: "auto", textAlign: "left" }}>
                                    {"Our architecture design made it possible to smoothly work on different aspects of the system in parallel, so that Audi could meet the breakneck deadline and keep the product launch date. The solution worked flawlessly from day one."}
                                </TextBody>
                            </div>
                            <div>
                                <ThumbnailHeader style={{ margin: "auto" }}>
                                    {"Further evolution"}
                                </ThumbnailHeader>
                                <TextBody style={{ margin: "auto", textAlign: "left" }}>
                                    {"The e-tron reservation system proved to be a success and continues to be in use today. Through our high quality design and delivery we empowered Audi’s team to continuously improve the platform as a living digital product. "}
                                </TextBody>
                            </div>
                        </ToutRow>

                    </ContentWrapper>
                    <MoveHack top={"-50px"} topMobile={'100px'}/>
                    <SolutionParagraph style={{maxWidth: "100%", textAlign: "left", paddingBottom: "100px"}}>
                        All in all, the e-tron reservation platform was launched on time and performed perfectly from
                        the get-go, which we still feel was next to impossible given the tremendously short timespan.
                        And yet we did it, thus helping Audi earn the badge of the most progressive of the traditional
                        automotive brands by means of high-tech marketing to match their high-quality products.
                    </SolutionParagraph>
                </CenteredContentWrapper>
            </AlternateBackground>

            <MoveHack top={"-50px"}/>

            <TextToutFullwidth {...contactUsFooterProps} />
        </>
    );
}

const AudiCaseStudy = () => (
    <StaticQuery
        query={graphql`
      query {
        howItWorksBg: file(
          relativePath: { eq: "sectionhow-it-works-bg@2x.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
        render={data => {
            return <LayoutDefault>
                <Page data={data} />
            </LayoutDefault>
        }}
    />
);

export default AudiCaseStudy

const SectionHeaderStyled = styled(SectionHeader)`
  max-width: unset;
  text-align: center !important;
  margin-top: 16px;
  margin-bottom: 24px;

  @media ${device.mobile} {
    margin-top: 50px;
    font-size: 28px;
  }
`;

const StyledTextBody = styled(TextBody)`
  text-align: left;
  @media ${device.mobile} {
    max-width: 90%;  
  }
  max-width: 70%;
`

const Solution = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  @media ${device.mobile} {
    flex-direction: column;
    gap: 0;
  }
`

const SolutionParagraph = styled.p`
  flex: 1;
  color: #6b726f;
  font-size: 18px;
  line-height: 1.5;
`

const AlignToLeftOnMobileSolutionParagraph = styled(SolutionParagraph)`
  max-width: 100%;
  font-size: 20px;
  text-align: center;

    @media ${device.mobile} {
        text-align: left;
    }
`
